const _Environments = {
  production: {
    env: "production",
    USER_URL: "https://production-hospital-api.prr.ai",
    LOGS: true,
    AUTH0_DOMAIN: "softsensor.us.auth0.com",
    AUTH0_CLIENT_ID: "woqrBrpwkHtSLqm3K8nzyG1loJnCPyQO",
    AUTH0_REDIRECT_URI: "redirect",
    AUTH0_AUDIENCE: "aud://softsensor.prod",
    AUTH0_CONNECTION: "hospital-web",
    AUTH0_TOKEN:
      "@@auth0spajs@@::woqrBrpwkHtSLqm3K8nzyG1loJnCPyQO::aud://softsensor.prod::openid profile email offline_access",
    VIEWER_URL: "https://production-api.viewer.prr.ai",
    VIEWER_SOCKET_URL: "wss://production-api.viewer.prr.ai",
  },
  staging: {
    env: "staging",
    USER_URL: "https://staging-hospital-api.prr.ai",
    LOGS: true,
    AUTH0_DOMAIN: "softsensor-development.us.auth0.com",
    AUTH0_CLIENT_ID: "IiSUEdqN592Eg756LAqBzNIhax2ogXuA",
    AUTH0_REDIRECT_URI: "redirect",
    AUTH0_AUDIENCE: "aud://softsensor.dev",
    AUTH0_CONNECTION: "hospital-staging",
    AUTH0_TOKEN:
      "@@auth0spajs@@::IiSUEdqN592Eg756LAqBzNIhax2ogXuA::aud://softsensor.dev::openid profile email offline_access",
    VIEWER_URL: "https://staging-api.viewer.prr.ai",
    VIEWER_SOCKET_URL: "wss://staging-api.viewer.prr.ai",
  },
  development: {
    env: "development",
    USER_URL: "https://development-hospital-api.prr.ai",
    LOGS: true,
    AUTH0_DOMAIN: "softsensor-development.us.auth0.com",
    AUTH0_CLIENT_ID: "Zy7RJs0eOtOE4xkaCec6WZBgvHtJbXYx",
    AUTH0_REDIRECT_URI: "redirect",
    AUTH0_AUDIENCE: "aud://softsensor.dev",
    AUTH0_CONNECTION: "hospital-development",
    AUTH0_TOKEN:
      "@@auth0spajs@@::Zy7RJs0eOtOE4xkaCec6WZBgvHtJbXYx::aud://softsensor.dev::openid profile email offline_access",
    VIEWER_URL: "https://development-api.viewer.prr.ai",
    VIEWER_SOCKET_URL: "wss://development-api.viewer.prr.ai",
  },
  local: {
    env: "local",
    USER_URL:
      process.env.REACT_APP_URL || "https://development-hospital-api.prr.ai",
    LOGS: true,
    AUTH0_DOMAIN: "softsensor-development.us.auth0.com",
    AUTH0_CLIENT_ID: "Zy7RJs0eOtOE4xkaCec6WZBgvHtJbXYx",
    AUTH0_REDIRECT_URI: "redirect",
    AUTH0_AUDIENCE: "aud://softsensor.dev",
    AUTH0_CONNECTION: "hospital-development",
    AUTH0_TOKEN:
      "@@auth0spajs@@::Zy7RJs0eOtOE4xkaCec6WZBgvHtJbXYx::aud://softsensor.dev::openid profile email offline_access",
    VIEWER_URL: "https://development-api.viewer.prr.ai",
    VIEWER_SOCKET_URL: "wss://development-api.viewer.prr.ai",
  },
};

function getEnvironment() {
  let env = "development";
  if (window.location.href.includes("localhost")) {
    env = "local";
  } else if (window.location.href.includes("development")) {
    env = "development";
  } else if (window.location.href.includes("staging")) {
    env = "staging";
  } else if (window.location.href.includes("production")) {
    env = "production";
  }
  return _Environments[env];
}

const Environment = getEnvironment();
export default Environment;
