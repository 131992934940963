import React, { useState } from "react";
import {
  Box,
  Text,
  HStack,
  Flex,
  Link,
  VStack,
  Image,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link as RouteLink } from "react-router-dom";
import { FiAlertTriangle, FiMoreVertical } from "react-icons/fi";
import axios from "axios";
import { getSlideUrl } from "../hooks/utility";
import useUserAuthentication from "../hooks/useUserAuthentication";
import {
  useLazyGetCaseInfoQuery,
  useLazyGetRecentSlidesQuery,
} from "../state/API/HospitalApi";
import Loading from "../Components/Loading/loading";
import Environment from "../environment";
import { onMessageListener } from "../Firebase/init";
import SlideEditModal from "./SlideEditModal";
import ConfirmationModal from "./ConfirmationModal";

const SlidesInfo = ({
  caseId,
  slides,
  isLoading,
  stainType,
  setNotifyOpenSlide,
  caseInfo,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = (value) => {
    setSelectedValue(value);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = (value) => {
    setSelectedValue(value);
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const toast = useToast();
  const { user } = useAuth0();
  const isUserAuthenticated = useUserAuthentication();
  const [getCaseInfo] = useLazyGetCaseInfoQuery();
  const [getRecentSlides] = useLazyGetRecentSlidesQuery();
  onMessageListener()
    .then((payload) => {
      if (payload.notification) {
        const { title, body } = payload.notification;
        toast({
          title: title || "Notification",
          description: body || "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } else {
        setNotifyOpenSlide(payload.data.caseId);
      }
      if (payload.data && payload.data.caseId) {
        getCaseInfo({ caseId }).unwrap();
        getRecentSlides({ caseId }).unwrap();
      }
    })
    .catch((err) => {
      toast({
        title: "Server Error",
        description: err.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error(err);
    });

  const updateSlideOpened = async (slideId, caseid, subClaim) => {
    const resp = await axios.post(`${Environment.USER_URL}/slide_opened`, {
      slideId: `${slideId}`,
      caseId: `${caseid}`,
      subClaim: `${subClaim}`,
    });
  };

  return !isUserAuthenticated || isLoading ? (
    <Loading />
  ) : (
    <Flex
      mr="-0rem !important"
      mb="1.1vh"
      flexWrap="wrap"
      mt="1.32vh"
      fontSize="12px"
      flexDirection="column"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#C4C4C4",
        },
      }}
      w="100%"

      // background="light.100"
    >
      {slides?.length === 0 ? (
        <Flex
          w="100%"
          h="72vh"
          direction="column"
          align="center"
          justifyContent="center"
        >
          <FiAlertTriangle size="1.5vw" />
          <Text fontSize="14px">No slide is uploaded!</Text>
        </Flex>
      ) : (
        <Flex
          mt="1.1vh"
          alignItems="flex-start"
          // mr="-0rem !important"
          flexWrap="wrap"
          marginStart="1vw"
          minH="72vh"
        >
          {slides
            ?.filter((slide) => {
              if (stainType === "all") return slide;
              if (stainType === slide?.stainType) {
                return slide;
              }
              return null;
            })
            .map((slide) => {
              return slide?.awsImageBucketUrl !== "" ? (
                <Box
                  bgColor="light.100"
                  w="15%"
                  h="100%"
                  mr="1.4vw"
                  mb="1.4vw"
                  pb="0.8vw"
                  borderRadius="10px"
                >
                  <Flex justifyContent="flex-end">
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        icon={<FiMoreVertical color="black" />}
                        colorScheme="black"
                        height="30px"
                        width="30px"
                        zIndex={10}
                      />
                      <MenuList minWidth="80px">
                        <MenuItem onClick={() => handleOpenModal(slide)}>
                          Edit Slide
                        </MenuItem>
                        <MenuItem onClick={() => openModal(slide)}>
                          Delete Slide
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                  <Link
                    as={RouteLink}
                    key={slide?.accessionId}
                    to="/viewer"
                    style={{ textDecoration: "none" }}
                    _focus={{ outline: "none" }}
                    state={{ caseId, slide }}
                    onClick={() =>
                      updateSlideOpened(slide._id, caseId, user?.sub)
                    }
                  >
                    <Box w="100%" maxH="37vh" minH="37vh">
                      <VStack px="auto">
                        <Image
                          src={getSlideUrl(slide?.awsImageBucketUrl)}
                          w="90%"
                          h="23vh"
                          alt={slide.accessionId}
                          pt="1vh"
                        />

                        <HStack
                          w="100%"
                          justifyContent="space-between"
                          px="1.1vw"
                        >
                          <Text w="80%">{`${
                            slide?.slideName
                              ? slide.slideName
                              : slide.originalName
                          }`}</Text>
                        </HStack>
                      </VStack>
                    </Box>
                  </Link>
                </Box>
              ) : (
                ""
              );
            })}
        </Flex>
      )}
      <SlideEditModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        selectedValue={selectedValue}
        caseInfo={caseInfo}
      />
      <ConfirmationModal
        selectedValue={selectedValue}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </Flex>
  );
};
export default SlidesInfo;
