import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Box,
  Image,
  Text,
  Input,
  RadioGroup,
  Radio,
  HStack,
  MenuItemOption,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Button,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyGetCaseInfoQuery } from "../state/API/HospitalApi";
import { getSlideUrl } from "../hooks/utility";
import Environment from "../environment";

const SlideEditModal = ({ isOpen, onClose, selectedValue, caseInfo }) => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const [is1280] = useMediaQuery("(width: 1280px)");
  const toast = useToast();
  const state = useLocation();
  const [getCaseInfo] = useLazyGetCaseInfoQuery();
  const [accessionId, setAccessionId] = useState(selectedValue?.accessionId);
  const [slideType, setSlideType] = useState(selectedValue?.stainType);
  const [slideName, setSlideName] = useState(
    selectedValue?.slideName !== undefined &&
      selectedValue.slideName.slice(0, -3) +
        selectedValue.slideName.split("_")[4]
  );
  const [strainType, setStrainType] = useState(
    selectedValue?.bioMarkerType === "null"
      ? "Select marker type*"
      : selectedValue.bioMarkerType
  );
  useEffect(() => {
    setAccessionId(selectedValue?.accessionId);
    setSlideType(selectedValue?.stainType);
    setStrainType(
      selectedValue?.bioMarkerType === "null"
        ? "Select marker type*"
        : selectedValue.bioMarkerType
    );
  }, [selectedValue]);

  useEffect(() => {
    setSlideName(
      selectedValue?.slideName !== undefined &&
        selectedValue.slideName.slice(0, -3) + slideType
    );
  }, [slideType, selectedValue]);

  const onEditChange = async () => {
    const resp = await axios.put(
      `${Environment.USER_URL}/edit_slide_info?_id=${selectedValue?._id}`,
      {
        accessionId,
        bioMarkerType: slideType === "IHC" ? strainType : "null",
        stainType: slideType,
        slideName,
      }
    );
    if (resp.status === 200) {
      toast({
        title: "Slide details updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      getCaseInfo({ caseId: state.state.caseId }).unwrap();
    } else {
      toast({
        title: "Some error occured",
        description: "Please try again after some time",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize={ifSmallScreen ? "13px" : "15.5px"}
          font-weight="500"
          pl={ifSmallScreen ? "20px" : "27px"}
          pb={ifSmallScreen ? "10px" : "15px"}
          pt={ifSmallScreen ? "10px" : "19px"}
          bg="#F0F2FF"
          borderStyle="solid"
          borderWidth="0px 0px 2px 0px"
          borderColor="#DEDEDE"
        >
          Edit Slide
        </ModalHeader>
        <ModalCloseButton
          pb={ifSmallScreen ? "20px" : "15px"}
          pt={ifSmallScreen ? "10px" : "21px"}
          mr={ifSmallScreen ? "10px" : "10.5"}
          _hover={{ opacity: 1 }}
        />
        <ModalBody>
          <Flex justifyContent="space-between">
            <Box w="45%">
              <Flex justifyContent="center" alignItems="center">
                <Image
                  src={getSlideUrl(selectedValue?.awsImageBucketUrl)}
                  alt="Image description"
                  objectFit="contain"
                  css={{ cursor: "-webkit-grab" }}
                  pr="40px"
                />
              </Flex>
            </Box>
            <Box w="55%">
              <Flex flexDirection="column">
                <Box>
                  <Text
                    color="#212224"
                    fontWeight="bold"
                    fontSize="15px"
                    pb="8px"
                  >
                    Case Details
                  </Text>
                  <Text fontSize="13px">
                    Case ID:
                    <span style={{ padding: "0 35px" }}>
                      {selectedValue?.slideName !== undefined &&
                        selectedValue.slideName.split("_")[2]}
                    </span>
                  </Text>
                  <Text fontSize="13px">
                    Department:{" "}
                    <span style={{ padding: "0 7px" }}>
                      {caseInfo?.departmentFrom.toLowerCase()}
                    </span>
                  </Text>
                  <Text fontSize="13px">
                    Organ:{" "}
                    <span style={{ padding: "0 45px" }}>
                      {caseInfo?.organs[0].organName}
                    </span>
                  </Text>
                </Box>
                <Box>
                  <Text
                    color="#212224"
                    fontWeight="bold"
                    fontSize="15px"
                    pb="8px"
                    pt="10px"
                  >
                    Patient Details
                  </Text>
                  <Text fontSize="13px">
                    UHID:{" "}
                    <span style={{ padding: "0 50px" }}>
                      {caseInfo?.patient.uhid}
                    </span>
                  </Text>
                  <Text fontSize="13px">
                    Name:{" "}
                    <span style={{ padding: "0 47px" }}>
                      {`${caseInfo?.patient?.patientName?.firstName}${caseInfo?.patient?.patientName?.lastName}`}{" "}
                    </span>
                  </Text>
                </Box>
                <Box>
                  <Text
                    color="#999999"
                    fontWeight="bold"
                    fontSize="15px"
                    pb="8px"
                    pt="10px"
                  >
                    Slide Title*
                  </Text>
                  <Input
                    border="1px solid #DEDEDE"
                    _focus={{ border: "1px solid #DEDEDE" }}
                    fontSize="13px"
                    value={slideName}
                    onChange={(e) => setSlideName(e.target.value)}
                  />
                </Box>
                <Box>
                  <Text
                    color="#999999"
                    fontWeight="bold"
                    fontSize="15px"
                    pb="8px"
                    pt="10px"
                  >
                    Accession ID*
                  </Text>
                  <Input
                    border="1px solid #DEDEDE"
                    _focus={{ border: "1px solid #DEDEDE" }}
                    fontSize="13px"
                    value={accessionId}
                    onChange={(e) => setAccessionId(e.target.value)}
                  />
                </Box>
                <Box pb="8px" pt="15px">
                  <RadioGroup
                    onChange={(e) => setSlideType(e)}
                    defaultValue={slideType}
                  >
                    <HStack spacing="20px">
                      <Radio
                        value="IHC"
                        _focus={{ outline: "none" }}
                        borderRadius={0}
                      >
                        <Text fontSize="13px">IHC</Text>
                      </Radio>
                      <Radio
                        value="STN"
                        _focus={{ outline: "none" }}
                        borderRadius={0}
                      >
                        <Text fontSize="13px"> Special stain</Text>
                      </Radio>
                      <Radio
                        value="H&E"
                        _focus={{ outline: "none" }}
                        borderRadius={0}
                      >
                        <Text fontSize="13px"> H&E</Text>
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </Box>
                <Box>
                  <Menu>
                    <MenuButton
                      as={Button}
                      bgColor="#F6F6F6"
                      border="1px solid #DEDEDE"
                      w="250px"
                      fontWeight="500"
                      borderRadius={0}
                      mb="8px"
                      mt="10px"
                      disabled={slideType !== "IHC"}
                    >
                      <Flex
                        alignItems="center"
                        w="100%"
                        justifyContent="space-between"
                      >
                        <Text fontSize="13px">
                          {strainType !== null
                            ? strainType
                            : "Select marker type*"}
                        </Text>
                        <ChevronDownIcon />
                      </Flex>
                    </MenuButton>
                    <MenuList minWidth="250px" borderRadius={0} p={0}>
                      <MenuOptionGroup
                        defaultValue="asc"
                        type="radio"
                        onChange={(e) => setStrainType(e)}
                      >
                        <MenuItemOption
                          value="kI67"
                          _hover={{ bgColor: "#1B75BC6E" }}
                        >
                          <Text fontSize="13px">KI-67</Text>
                        </MenuItemOption>
                        <MenuItemOption
                          value="HER-2"
                          _hover={{ bgColor: "#1B75BC6E" }}
                        >
                          <Text fontSize="13px">HER-2</Text>
                        </MenuItemOption>
                        <MenuItemOption
                          value="CD20"
                          _hover={{ bgColor: "#1B75BC6E" }}
                        >
                          <Text fontSize="13px"> CD20</Text>
                        </MenuItemOption>
                        <MenuItemOption
                          value="EGFR"
                          _hover={{ bgColor: "#1B75BC6E" }}
                        >
                          <Text fontSize="13px"> EGFR</Text>
                        </MenuItemOption>
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </Box>
                <Box pb="20px" pt="20px">
                  <HStack justifyContent="flex-end">
                    <Flex gap="30px">
                      <Button
                        alignItems="center"
                        bgColor="#fff"
                        color="#010202"
                        border="1px solid #CFE2F1"
                        size="md"
                        width="90px"
                        height="30px"
                        fontSize="13px"
                        _focus={{ outline: "none" }}
                        borderRadius="5px"
                        fontWeight="400"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        alignItems="center"
                        bgColor="#1B75BC"
                        color="light.100"
                        _hover={{ bgColor: "#1B75BC" }}
                        size="md"
                        _focus={{ outline: "none" }}
                        borderRadius="5px"
                        fontWeight="400"
                        width="90px"
                        height="30px"
                        fontSize="13px"
                        onClick={() => {
                          onEditChange();
                          onClose();
                        }}
                      >
                        Save
                      </Button>
                    </Flex>
                  </HStack>
                </Box>
              </Flex>
            </Box>
          </Flex>
          {/* Display the selected value inside the modal */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SlideEditModal;
