import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyGetCaseInfoQuery } from "../state/API/HospitalApi";
import { getSlideUrl } from "../hooks/utility";
import Environment from "../environment";

const ConfirmationModal = ({ isOpen, onClose, selectedValue }) => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const [is1280] = useMediaQuery("(width: 1280px)");
  const [getCaseInfo] = useLazyGetCaseInfoQuery();
  const state = useLocation();
  const toast = useToast();
  const handleDelete = async () => {
    const resp = await axios.post(`${Environment.USER_URL}/delete_slide`, {
      slideId: selectedValue._id,
    });
    if (resp.status === 200) {
      toast({
        title: "Slide Delete Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      getCaseInfo({ caseId: state.state.caseId }).unwrap();
    } else {
      toast({
        title: "Some error occured",
        description: "Please try again after some time",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize={ifSmallScreen ? "13px" : "15.5px"}
          font-weight="500"
          pl={ifSmallScreen ? "20px" : "27px"}
          pb={ifSmallScreen ? "10px" : "15px"}
          pt={ifSmallScreen ? "10px" : "19px"}
          bg="#F0F2FF"
          borderStyle="solid"
          borderWidth="0px 0px 2px 0px"
          borderColor="#DEDEDE"
        >
          Confirmation
        </ModalHeader>
        <ModalCloseButton
          pb={ifSmallScreen ? "20px" : "15px"}
          pt={ifSmallScreen ? "10px" : "21px"}
          mr={ifSmallScreen ? "10px" : "10.5"}
          _hover={{ opacity: 1 }}
        />
        <ModalBody>
          <Text fontSize="14px">
            This slide will be deleted permanently. Are you sure you want to
            delete this slide?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-beteween" gap="15px">
            <Button
              alignItems="center"
              bgColor="#fff"
              color="#010202"
              border="1px solid #CFE2F1"
              size="md"
              width="90px"
              height="30px"
              fontSize="13px"
              _focus={{ outline: "none" }}
              borderRadius="5px"
              fontWeight="400"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              alignItems="center"
              bgColor="#1B75BC"
              color="light.100"
              _hover={{ bgColor: "#1B75BC" }}
              size="md"
              _focus={{ outline: "none" }}
              borderRadius="5px"
              fontWeight="400"
              width="90px"
              height="30px"
              fontSize="13px"
              onClick={() => {
                handleDelete();
                onClose();
              }}
            >
              Delete
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
