import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Environment from "../../environment";

const HospitalApiSlice = createApi({
  reducerPath: "HospitalApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Environment.USER_URL}`,
    prepareHeaders: (headers) => {
      const { body } = JSON.parse(
        localStorage.getItem(Environment.AUTH0_TOKEN)
      );

      if (body && typeof body === "object") {
        const { access_token: accessToken } = body;
        if (accessToken) headers.set("authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "cases",
    "case",
    "archive_case",
    "slide_report",
    "add_user",
    "register",
    "synoptic_report",
    "recent_slides",
  ],
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: (body) => ({
        url: "get_user_information",
        method: "POST",
        body,
      }),
      providesTags: ["archive_case", "register"],
    }),
    createCase: builder.mutation({
      query: (body) => ({
        url: "create_new_case",
        method: "POST",
        body,
      }),
      invalidatesTags: ["cases"],
    }),
    searchUser: builder.mutation({
      query: (body) => ({
        url: "search_user_by_email",
        method: "POST",
        body,
      }),
      invalidatesTags: ["cases"],
    }),
    getCasesFromOrganization: builder.query({
      query: (body) => ({
        url: "get_cases_from_organization",
        method: "POST",
        body,
      }),
      providesTags: ["cases", "archive_case"],
    }),
    getCaseInfo: builder.query({
      query: (body) => ({
        url: "get_case_info",
        method: "POST",
        body,
      }),
      providesTags: ["cases", "case", "slide_report", "synoptic_report"],
    }),
    addUserToCase: builder.mutation({
      query: (body) => ({
        url: "assign_case_to_user",
        method: "POST",
        body,
      }),
      invalidatesTags: ["cases"],
    }),
    addMultipleUserToCase: builder.mutation({
      query: (body) => ({
        url: "add_multiple_users_to_case",
        method: "POST",
        body,
      }),
      invalidatesTags: ["cases"],
    }),
    getCasesStatistics: builder.query({
      query: (body) => ({
        url: "get_statistics",
        method: "POST",
        body,
      }),
    }),
    getCaseDates: builder.query({
      query: (body) => ({
        url: "get_cases_by_date",
        method: "POST",
        body,
      }),
    }),
    addClinicalHistoryToCase: builder.mutation({
      query: (body) => ({
        url: "add_clinical_history",
        method: "POST",
        body,
      }),
    }),
    addGrossToCase: builder.mutation({
      query: (body) => ({
        url: "add_gross_to_case",
        method: "POST",
        body,
      }),
    }),
    multimediaUpload: builder.mutation({
      query: (body) => ({
        url: "multi_media_file_upload",
        method: "POST",
        body,
      }),
    }),
    saveAnnotation: builder.mutation({
      query: (body) => ({
        url: "annotation",
        method: "POST",
        body: { ...body, app: "hospital" },
      }),
    }),
    loadAnnotations: builder.query({
      query: (body) => ({
        url: "load_annotations",
        method: "POST",
        body,
      }),
    }),
    deleteAnnotation: builder.mutation({
      query: ({ hash, slideId }) => ({
        url: "annotation",
        method: "DELETE",
        body: { hash, slideId },
      }),
    }),
    updateAnnotation: builder.mutation({
      query: ({ hash, slideId, updateObject }) => ({
        url: "annotation",
        method: "PATCH",
        body: { hash, slideId, updateObject },
      }),
    }),
    organizationDetails: builder.query({
      query: (body) => ({
        url: "get_organization_details",
        method: "POST",
        body,
      }),
    }),

    archiveCase: builder.mutation({
      query: (body) => ({
        url: "case_update_archive",
        method: "POST",
        body,
      }),
      invalidatesTags: ["archive_case", "cases"],
    }),
    saveReport: builder.mutation({
      query: (body) => ({
        url: "save_slide_report",
        method: "POST",
        body,
      }),
      invalidatesTags: ["cases", "slide_report"],
    }),
    multiFileUpload: builder.mutation({
      query: (body) => ({
        url: "multi_media_file_upload",
        method: "POST",
        body,
      }),
      invalidatesTags: ["cases"],
    }),
    getSlideInfo: builder.query({
      query: (body) => ({
        url: "get_slide_info",
        method: "POST",
        body,
      }),
      providesTags: ["slide_report", "cases"],
    }),
    updateFCMToken: builder.mutation({
      query: (body) => ({
        url: "update_fcm_token",
        method: "POST",
        body,
      }),
    }),
    vhutAnalysis: builder.mutation({
      query: (body) => ({
        url: "vhut_analysis",
        method: "POST",
        body,
      }),
    }),
    vhutViewportAnalysis: builder.mutation({
      query: (body) => ({
        url: "vhut_viewport_analysis",
        method: "POST",
        body: {
          ...body,
          notifyHook: `${Environment.USER_URL}/notify_viewport_analysis`,
        },
      }),
    }),
    getVhutAnalysis: builder.query({
      query: (body) => ({
        url: "get_vhut_analysis",
        method: "POST",
        body,
      }),
    }),
    getPathologistList: builder.query({
      query: (body) => ({
        url: "get_pathologist_list",
        method: "POST",
        body,
      }),
    }),
    createUserAndOrg: builder.mutation({
      query: (body) => ({
        url: "create_new_user_org",
        method: "POST",
        body,
      }),
      invalidatesTags: ["register"],
    }),
    organizationUserDetails: builder.query({
      query: (body) => ({
        url: "get_organization_users",
        method: "POST",
        body,
      }),
      providesTags: ["add_user"],
    }),
    addUserToOrg: builder.mutation({
      query: (body) => ({
        url: "add_user_to_org",
        method: "POST",
        body,
      }),
      invalidatesTags: ["add_user"],
    }),
    getFilterData: builder.query({
      query: (body) => ({
        url: "get_cases_by_filter",
        method: "POST",
        body,
      }),
      providesTags: ["cases", "archive_case"],
    }),
    getFilterList: builder.query({
      query: (body) => ({
        url: "get_filter_list",
        method: "POST",
        body,
      }),
    }),
    getOrganizationUsers: builder.query({
      query: (body) => ({
        url: "get_organization_users",
        method: "POST",
        body,
      }),
    }),
    getFilterCases: builder.query({
      query: (body) => ({
        url: "get_filter_list",
        method: "POST",
        body,
      }),
    }),
    getCasesWithoutSlides: builder.query({
      query: (body) => ({
        url: "cases_without_slides",
        method: "POST",
        body,
      }),
      providesTags: ["cases"],
    }),
    getTimeline: builder.query({
      query: (body) => ({
        url: "case_timeline",
        method: "POST",
        body,
      }),
    }),
    getSharedCases: builder.query({
      query: (body) => ({
        url: "get_shared_cases",
        method: "POST",
        body,
      }),
    }),
    getRecentSlides: builder.query({
      query: (body) => ({
        url: "recent_slides",
        method: "POST",
        body,
      }),
      providesTags: ["recent_slides"],
    }),
    getRecentChats: builder.query({
      query: (body) => ({
        url: "recent_chats",
        method: "POST",
        body,
      }),
    }),
    updateGross: builder.query({
      query: (body) => ({
        url: "update_gross_of_case",
        method: "POST",
        body,
      }),
      invalidatesTags: ["case"],
    }),
    deleteClinicalURLs: builder.mutation({
      query: (body) => ({
        url: "delete_clinical_urls",
        method: "POST",
        body,
      }),
      invalidatesTags: ["case"],
    }),
    deleteRadiologyURLs: builder.mutation({
      query: (body) => ({
        url: "delete_radiology_image",
        method: "POST",
        body,
      }),
      invalidatesTags: ["case"],
    }),
    updateClinicalHistory: builder.mutation({
      query: (body) => ({
        url: "update_clinical_history",
        method: "POST",
        body,
      }),
      invalidatesTags: ["case"],
    }),
    deleteGrossOfCase: builder.mutation({
      query: (body) => ({
        url: "delete_gross_of_case",
        method: "POST",
        body,
      }),
      invalidatesTags: ["case"],
    }),
    updateGrossHistory: builder.mutation({
      query: (body) => ({
        url: "update_gross_of_case",
        method: "POST",
        body,
      }),
      invalidatesTags: ["case"],
    }),
    addRadiologyToCase: builder.mutation({
      query: (body) => ({
        url: "add_radiology_info",
        method: "POST",
        body,
      }),
      invalidatesTags: ["case"],
    }),
    deleteCase: builder.mutation({
      query: (body) => ({
        url: "delete_case",
        method: "POST",
        body,
      }),
    }),
    updateRadiologyInfo: builder.mutation({
      query: (body) => ({
        url: "update_radiology",
        method: "POST",
        body,
      }),
      invalidatesTags: ["cases"],
    }),
    getReportInfo: builder.query({
      query: (body) => ({
        url: "get_slide_info",
        method: "POST",
        body,
      }),
      providesTags: ["cases", "case", "slide_report", "synoptic_report"],
    }),
    saveSynopticReport: builder.mutation({
      query: (body) => {
        const { reportType } = body;
        return {
          url: `slide/v1/${reportType}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["synoptic_report"],
    }),
    getCountOfCases: builder.query({
      query: () => {
        return {
          url: `case/v1/cases-count`,
          method: "GET",
        };
      },
    }),

    getSynopticReport: builder.query({
      query: (body) => {
        const { reportType, caseId } = body;
        return {
          url: `slide/v1/${reportType}/${caseId}`,
          method: "GET",
        };
      },
      providesTags: ["synoptic_report"],
    }),

    updateSynopticReport: builder.mutation({
      query: (body) => {
        const { reportType, caseId } = body;
        return {
          url: `slide/v1/${caseId}/${reportType}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["synoptic_report"],
    }),
    getUsersList: builder.query({
      query: (body, caseId) => {
        const { username } = body;
        return {
          url: "users/v1/users-name",
          method: "GET",
          params: {
            firstName: username,
            caseId,
          },
        };
      },
      transformResponse: (response, meta) => {
        if (response && response.success) {
          const users = response.data.map((user) => ({
            id: user.subClaim,
            display: user.lastName
              ? `${user.firstName} ${user.lastName}`
              : user.firstName,
            userId: user._id,
          }));

          return users;
        }

        return response;
      },
    }),
    universalSearch: builder.query({
      query: (body) => {
        return {
          url: `case/v1/universal-search`,
          method: "GET",
          params: {
            ...body,
          },
        };
      },
    }),
    caseSearch: builder.query({
      query: (body) => {
        const { caseId, payload } = body;
        return {
          url: `/case/v1/${caseId}/viewer-slides-search?payload=${payload}`,
          method: "GET",
        };
      },
    }),
    getRecentUserChat: builder.query({
      query: (body) => {
        const { userId } = body;
        return {
          url: `recent_chats?userId=${userId}`,
          method: "GET",
        };
      },
    }),
    getUsersListInOrg: builder.query({
      query: (body) => ({
        url: "get_organization_users_list",
        method: "GET",
        params: {
          ...body,
        },
      }),
    }),

    searchUsersByName: builder.query({
      query: (body) => {
        return {
          url: `search_user_by_name`,
          method: "GET",
          params: {
            ...body,
          },
        };
      },
    }),
    searchCaseByID: builder.query({
      query: (body) => ({
        url: "search_case_by_id",
        method: "GET",
        params: {
          ...body,
        },
      }),
    }),
  }),
});

export const {
  useGetCasesWithoutSlidesQuery,
  useGetUserInfoQuery,
  useCreateCaseMutation,
  useGetCasesFromOrganizationQuery,
  useGetCaseInfoQuery,
  useGetFilterCasesQuery,
  useSearchUserMutation,
  useAddUserToCaseMutation,
  useGetCasesStatisticsQuery,
  useAddClinicalHistoryToCaseMutation,
  useGetCaseDatesQuery,
  useAddGrossToCaseMutation,
  useMultimediaUploadMutation,
  useSaveAnnotationMutation,
  useLazyLoadAnnotationsQuery,
  useOrganizationDetailsQuery,
  useArchiveCaseMutation,
  useSaveReportMutation,
  useMultiFileUploadMutation,
  useLazyGetSlideInfoQuery,
  useDeleteAnnotationMutation,
  useUpdateAnnotationMutation,
  useUpdateFCMTokenMutation,
  useVhutAnalysisMutation,
  useVhutViewportAnalysisMutation,
  useLazyGetVhutAnalysisQuery,
  useGetPathologistListQuery,
  useCreateUserAndOrgMutation,
  useOrganizationUserDetailsQuery,
  useAddUserToOrgMutation,
  useGetFilterDataQuery,
  useLazyGetFilterDataQuery,
  useGetFilterListQuery,
  useDeleteClinicalURLsMutation,
  useDeleteRadiologyURLsMutation,
  useUpdateClinicalHistoryMutation,
  useDeleteGrossOfCaseMutation,
  useUpdateGrossHistoryMutation,
  useAddRadiologyToCaseMutation,
  useDeleteCaseMutation,
  useUpdateRadiologyInfoMutation,
  useLazyGetCaseInfoQuery,
  useGetReportInfoQuery,
  useGetOrganizationUsersQuery,
  useSaveSynopticReportMutation,
  useLazyGetSynopticReportQuery,
  useGetUsersListQuery,
  useUpdateSynopticReportMutation,
  useAddMultipleUserToCaseMutation,
  useLazyUniversalSearchQuery,
  useLazyCaseSearchQuery,
  useGetTimelineQuery,
  useGetSharedCasesQuery,
  useGetRecentSlidesQuery,
  useLazyGetRecentSlidesQuery,
  useGetRecentChatsQuery,
  useGetRecentUserChatQuery,
  useLazyGetRecentUserChatQuery,
  useGetCountOfCasesQuery,
  useGetUsersListInOrgQuery,
  useSearchCaseByIDQuery,
  useSearchUsersByNameQuery,
} = HospitalApiSlice;
export default HospitalApiSlice;
