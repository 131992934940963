import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  HStack,
  Text,
  useMediaQuery,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { useAuth0 } from "@auth0/auth0-react";
import { BiPencil } from "react-icons/bi";
import { AiOutlineCaretDown } from "react-icons/ai";
import {
  useGetCaseInfoQuery,
  useGetUserInfoQuery,
  useGetReportInfoQuery,
  useLazyGetRecentSlidesQuery,
  useLazyGetCaseInfoQuery,
} from "../../state/API/HospitalApi";
import SlidesTab from "./SlidesTab";
import ClinicalHistory from "../../Case/ClinicalHistory";
import GrossImages from "../../Case/GrossImages";
import CaseOverview from "./CaseOverview/CaseOverview";
import AssignCase from "../../Case/AssignCase";
import TimelineExtended from "./TImelineExtended";
import ReportTab from "./ReportTab";
import { onMessageListener } from "../../Firebase/init";

const CaseInfoScreen = ({ caseId }) => {
  const toast = useToast();
  const state = useLocation();
  const [notifyOpenSlide, setNotifyOpenSlide] = useState("");
  const [getRecentSlides] = useLazyGetRecentSlidesQuery();
  const [getCaseInfo] = useLazyGetCaseInfoQuery();
  onMessageListener()
    .then((payload) => {
      if (payload.data.admin) {
        toast({
          title: "Action perform",
          description: `Action perform on the case`,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
      if (payload.data.pathologist) {
        toast({
          title: "Assign Case",
          description: `Case ID is ${payload.data.caseID}`,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
      if (payload.data && payload.data.caseId) {
        getCaseInfo({ caseId: state.state.caseId }).unwrap();
        getRecentSlides({ caseId: state.state.caseId }).unwrap();
      }
    })
    .catch((err) => {
      // toast({
      //   title: "Server Error",
      //   description: err.message,
      //   status: "error",
      //   duration: 2000,
      //   isClosable: true,
      // });
      console.error(err);
    });

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (index) => {
    setTabIndex(index);
    sessionStorage.setItem("tabIndex", index);
  };
  useEffect(() => {
    const storedIndex = sessionStorage.getItem("tabIndex");
    if (storedIndex) {
      setTabIndex(Number(storedIndex));
    }
  }, []);
  const blankSpace = "\u00A0";
  const { user } = useAuth0();
  const navigate = useNavigate();
  const { data: userInfo } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const { data: caseInfo, isLoading } = useGetCaseInfoQuery({
    caseId: state.state.caseId,
  });
  const { data: slideInfo } = useGetReportInfoQuery({
    caseId: state.state.caseId,
  });

  let report;
  let hiddenState;
  if (caseInfo?.isReported) {
    report = "Standard";
  } else if (caseInfo?.isSynopticReported) {
    if (caseInfo?.organs[0].organName === "breast") report = "Breast cancer";
    else if (caseInfo?.organs[0].organName === "lymph-nodes")
      report = "Lymphoma";
    else if (caseInfo?.organs[0].organName === "prostate-gland")
      report = "Prostate cancer";
  }
  if (userInfo?.userType === "technologist") {
    if (caseInfo?.timeLine?.length > 0) {
      for (let i = 0; i < caseInfo?.timeLine?.length; i++) {
        if (caseInfo?.timeLine[i].message.includes("reported by")) {
          hiddenState = "true";
          console.log("true reported value");
          break;
        }
      }
    }
  }

  return (
    <Flex w="100%" h="100%" direction="column">
      <HStack w="100%" h="10%" justifyContent="space-between">
        <Flex alignItems="center" ml="5px">
          <Button
            border="1px solid #E8E8EB"
            borderRadius="5px"
            bgColor="inherit"
            fontSize="13px"
            fontWeight={500}
            onClick={() => {
              navigate(`/dashboard/${userInfo?.userType}`);
              // localStorage.setItem("tab", 0);
              sessionStorage.clear();
            }}
          >
            <HStack>
              <IoArrowBackCircleSharp size={20} />
              <Text>Back</Text>
            </HStack>
          </Button>
          <Text
            fontSize={ifWidthLessthan1920 ? "22px" : "33px"}
            ml="20px"
            fontWeight={ifWidthLessthan1920 ? "500" : "600"}
          >
            {caseInfo?.caseName}
          </Text>
        </Flex>
        <Flex>
          <Menu>
            <MenuButton
              as={Button}
              fontSize={ifWidthLessthan1920 ? "14px" : "0.72916vw"}
              fontWeight="500"
              border="1px solid #E8E8EB"
              borderRadius="5px"
              bgColor="inherit"
              mr="12px"
              w="300px"
              disabled={hiddenState}
            >
              {caseInfo?.caseUsers[0]?.userId?.firstName === undefined &&
              caseInfo?.caseUsers[0]?.userId?.lastName === undefined ? (
                <Box>
                  <Flex justifyContent="space-between">
                    <Flex>
                      <Text weight="500">Case not assigned</Text>
                    </Flex>
                    <Flex ml="24px">
                      <AiOutlineCaretDown size="18px" />
                    </Flex>
                  </Flex>
                </Box>
              ) : (
                <Flex justifyContent="space-between">
                  {/* <Flex justifyContent="space-between"> */}
                  <Box
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    w="100%"
                    wordBreak="break-word"
                  >
                    <Text weight="500">
                      {"Assigned To: "}
                      {blankSpace}
                      <b>
                        Dr. {caseInfo?.caseUsers[0]?.userId?.firstName}
                        {blankSpace}
                        {caseInfo?.caseUsers[0]?.userId?.lastName}
                      </b>
                    </Text>
                  </Box>
                  <Box pl="10px">
                    <AiOutlineCaretDown size="18px" />
                  </Box>
                  {/* </Flex> */}
                </Flex>
              )}
            </MenuButton>
            <MenuList
              borderRadius="0px"
              borderColor="#3B5D7C"
              bg="light.100"
              p="0"
              zIndex="5"
            >
              <MenuItem
                _hover={{ bg: "light.100" }}
                bg="light.100"
                _focus={{ bg: "light.100" }}
                p="0"
                maxW="297px"
                minW="297px"
              >
                <AssignCase caseId={state.state.caseId} userInfo={userInfo} />
              </MenuItem>
            </MenuList>
          </Menu>

          {/* <Button
            border="1px solid #E8E8EB"
            borderRadius="5px"
            bgColor="inherit"
            fontSize="13px"
            fontWeight={500}
          >
            <HStack>
              <Text>Edit Case</Text>
              <BiPencil size={20} />
            </HStack>
          </Button> */}
        </Flex>
      </HStack>
      <Flex
        w="100%"
        h="100%"
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "0px",
          },
          "&::-webkit-scrollbar-track": {
            width: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
      >
        <Tabs
          w="100%"
          // onChange={(index) => setTabIndex(index)}
          onChange={handleTabChange}
          index={tabIndex}
          isLazy
        >
          <TabList
            h={ifWidthLessthan1920 ? "40px" : "60px"}
            css={{ position: "fixed" }}
            gap="20px"
            bgColor="light.100"
            zIndex="3"
            w="100%"
          >
            <Tab
              _selected={{
                color: "#1B75BC",
                borderBottom: "2px solid #1B75BC",
              }}
              w="10%"
              fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
              fontWeight={ifWidthLessthan1920 ? "500" : "600"}
            >
              Overview
            </Tab>
            <Tab
              _selected={{
                color: "#1B75BC",
                borderBottom: "2px solid #1B75BC",
              }}
              w="10%"
              fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
              fontWeight={ifWidthLessthan1920 ? "500" : "600"}
            >
              Slides
            </Tab>
            <Tab
              _selected={{
                color: "#1B75BC",
                borderBottom: "2px solid #1B75BC",
              }}
              w="12%"
              fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
              fontWeight={ifWidthLessthan1920 ? "500" : "600"}
            >
              Clinical History
            </Tab>
            <Tab
              _selected={{
                color: "#1B75BC",
                borderBottom: "2px solid #1B75BC",
              }}
              w="12%"
              fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
              fontWeight={ifWidthLessthan1920 ? "500" : "600"}
            >
              Gross Images
            </Tab>

            <Tab
              _selected={{
                color: "#1B75BC",
                borderBottom: "2px solid #1B75BC",
              }}
              w="10%"
              fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
              fontWeight={ifWidthLessthan1920 ? "500" : "600"}
            >
              Report
            </Tab>
            <Tab
              _selected={{
                color: "#1B75BC",
                borderBottom: "2px solid #1B75BC",
              }}
              w="10%"
              fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
              fontWeight={ifWidthLessthan1920 ? "500" : "600"}
            >
              Timeline
            </Tab>
          </TabList>

          <TabPanels zIndex="0" pt="40px">
            <TabPanel p={0}>
              <CaseOverview
                notifyOpenSlide={notifyOpenSlide}
                setNotifyOpenSlide={setNotifyOpenSlide}
                caseId={state.state.caseId}
                caseInfo={caseInfo}
                isLoading={isLoading}
                userInfo={userInfo}
                setTabIndex={setTabIndex}
              />
            </TabPanel>
            <TabPanel p={0}>
              <SlidesTab
                setNotifyOpenSlide={setNotifyOpenSlide}
                caseId={state.state.caseId}
                caseInfo={caseInfo}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel p="0">
              <ClinicalHistory caseInfo={caseInfo} />
            </TabPanel>
            <TabPanel p="0">
              <GrossImages caseInfo={caseInfo} />
            </TabPanel>
            <TabPanel p="0">
              <ReportTab
                caseInfo={caseInfo}
                slideInfo={slideInfo}
                userInfo={userInfo}
                report={report}
              />
            </TabPanel>
            <TabPanel>
              <TimelineExtended caseInfo={caseInfo} setTabIndex={setTabIndex} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  );
};
export default CaseInfoScreen;
