import React from "react";
import { Flex, Text, Button, Avatar, Box } from "@chakra-ui/react";
import { BiDotsVerticalRounded } from "react-icons/bi";

const ConversationHeader = ({ groupData }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      px="22px"
      p="5px"
      borderBottom="1px solid #dedede"
      minH=""
    >
      <Flex alignItems="center" gap="0.7rem">
        <Flex flexDir="column">
          <Text
            fontWeight="bold"
            textTransform="capitalize"
            fontSize="23px"
            pl="10px"
          >
            {groupData?.caseId
              ? groupData?.caseId
              : `${groupData?.firstName} ${groupData?.lastName}`}
          </Text>

          {/* <Text fontSize="12px">Case No-230-11022-UHID,</Text> */}
        </Flex>
      </Flex>
      <Box cursor="pointer">
        <BiDotsVerticalRounded style={{ fontSize: "20px" }} />
      </Box>
    </Flex>
  );
};

export default ConversationHeader;
