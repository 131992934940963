import React, { useEffect, useState } from "react";
import { Flex, Text, Vstack } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { AiFillLock } from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi";
import {
  useGetUserInfoQuery,
  useGetUsersListInOrgQuery,
  useSearchCaseByIDQuery,
  useSearchUsersByNameQuery,
} from "../../state/API/HospitalApi";
import Header from "../../DashboardLayout/Header";
import ChatHeader from "./ChatHeader";
import ChatNavbar from "./ChatNavbar";
import ChatLists from "./ChatLists";
import Conversation from "./Conversation";
import ConversationHeader from "./ConversationHeader";
import UserToUserConversation from "./UserToUserConversation";

const Chat = () => {
  const [searchName, setSearchName] = useState("");
  const [searchCase, setSearchCase] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [activeGroup, setActiveGroup] = useState(
    location?.state?.selectedGroup || null
  );
  const [groupData, setGroupData] = useState({
    _id: location.state?.selectedGroupData?._id || null,
    caseName: location.state?.selectedGroupData?.caseName || null,
  });
  const { user } = useAuth0();
  const { data: userInfo, isLoading } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });

  useEffect(() => {
    navigate(location.pathname, {});
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, []);

  return (
    <Flex h="100vh" w="100vw" flexDirection="column" overflow="hidden">
      <Header userInfo={userInfo} />
      <Flex w="100%" h="100vh" justifyContent="flex-start" flex="1" minH={0}>
        <Flex
          w="25%"
          h="93vh"
          pr="5px"
          flexDirection="column"
          overflow="hidden"
        >
          <ChatHeader
            index={index}
            setSearchName={setSearchName}
            setSearchCase={setSearchCase}
            setActiveGroup={setActiveGroup}
            setGroupData={setGroupData}
            setIndex={setIndex}
          />
          <ChatNavbar index={index} setIndex={setIndex} />
          <ChatLists
            searchName={searchName}
            searchCase={searchCase}
            setActiveGroup={setActiveGroup}
            setGroupData={setGroupData}
            activeGroup={activeGroup}
            tab={index}
          />
        </Flex>
        <Flex
          flex="3"
          bg="#f6f6f6"
          margin="0 0 0 5px"
          flexDir="column"
          pos="relative"
          w="75%"
          p="0.7rem 0.5rem"
        >
          {groupData?._id ? (
            <Flex
              bg="#fff"
              w="100%"
              h="100%"
              direction="column
            "
            >
              <ConversationHeader groupData={groupData} />
              {index === 0 ? (
                <UserToUserConversation
                  userInfo={userInfo}
                  groupChatId={groupData._id}
                  groupData={groupData}
                />
              ) : (
                <Conversation
                  userInfo={userInfo}
                  groupChatId={groupData._id}
                  groupData={groupData}
                />
              )}
            </Flex>
          ) : (
            <Flex
              w="100%"
              h="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
              pos="relative"
              bg="#fff"
            >
              <HiOutlineUserGroup fontSize="22px" />
              <Text fontSize="24px" fontWeight="500">
                Group Chat
              </Text>
              <Text fontSize="14px" color="#52585D">
                Send and receive messages
              </Text>
              <Text
                textAlign="center"
                pos="absolute"
                bottom="20px"
                display="flex"
                alignItems="center"
                gap="0.5rem"
                color="#52585D"
                fontSize="14px"
              >
                <AiFillLock /> Encrypted
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Chat;
