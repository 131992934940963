import React, { useEffect, useRef, useState } from "react";
import {
  Text,
  Box,
  Button,
  HStack,
  Input,
  VStack,
  useToast,
  Flex,
  Progress,
  Radio,
  useMediaQuery,
  RadioGroup,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  Tooltip,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { ChevronDownIcon, InfoIcon } from "@chakra-ui/icons";
import { MdFileUpload } from "react-icons/md";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import Environment from "../environment";

const UploadSlides = ({
  caseId,
  caseInfo,
  setClose,
  setLoading,
  openModel,
  setOpenModel,
}) => {
  const toast = useToast();
  const { user } = useAuth0();
  const [selectedFile, setSelectedFile] = useState(null);
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [ifWidthLessthan1500] = useMediaQuery("(max-width:1500px)");
  const [accessionId, setAccessionId] = useState(
    (Math.random() + 1).toString(36).substring(2, 10).toUpperCase()
  );
  const [grossId, setGrossId] = useState(null);
  const [blockId, setBlockId] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [slideType, setSlideType] = useState("IHC");
  const [strainType, setStrainType] = useState(null);
  const [progress, setProgress] = useState(0);

  const [slideName, setSlideName] = useState(
    `${accessionId}_${moment(new Date()).format("DD/MM/YYYY")}_${
      caseInfo.caseId
    }_${caseInfo?.organs[0].organName
      .replace(/[aeiou]/gi, "")
      .toLowerCase()}_${slideType}`
  );
  const updateSlideName = () => {
    return `${accessionId}_${moment(new Date()).format("DD/MM/YYYY")}_${
      caseInfo.caseId
    }_${caseInfo?.organs[0].organName
      .replace(/[aeiou]/gi, "")
      .toLowerCase()}_${slideType}`;
  };
  const uploadInputRef = useRef(null);

  const [abortController, setAbortController] = useState(null);

  const handleDragFile = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  const cancelFileSelection = () => {
    if (abortController) abortController.abort();
    setSelectedFile(null);
    setIsUploading(false);
    setProgress(0);
    toast({
      title: "Uploading cancelled",
      status: "info",
      duration: 1500,
      isClosable: true,
    });
  };

  const handleFileInputClick = () => {
    if (!uploadInputRef.current) return;
    uploadInputRef.current.click();
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    setSlideName(updateSlideName());
  }, [slideType, accessionId]);

  const uploadFiles = async () => {
    const form = new FormData();
    const controller = new AbortController();
    setOpenModel(false);
    setLoading(true);
    setClose(true);
    setIsUploading(true);
    setAbortController(controller);
    setAccessionId(
      (Math.random() + 1).toString(36).substring(2, 10).toUpperCase()
    );
    setSlideName(updateSlideName());
    try {
      form.append("subClaim", user.sub);
      form.append("caseId", caseInfo._id);
      form.append("uploadedAt", new Date());
      form.append("slideName", slideName);
      form.append("bioMarkerType", strainType);
      form.append("stainType", slideType);
      form.append("organ", caseInfo?.organs[0]?.organName);
      form.append("accessionId", accessionId);
      form.append("grossId", grossId);
      form.append("blockId", blockId);
      form.append("file", selectedFile);

      const config = {
        signal: controller.signal,
        onUploadProgress: (curProgress) => {
          const percentCompleted = Math.round(
            (curProgress.loaded * 100) / curProgress.total
          );

          setProgress(percentCompleted);
        },
      };

      const resp = await axios.post(
        `${Environment.USER_URL}/upload_file`,
        form,
        config
      );

      if (resp.status === 200) {
        toast({
          title: "Slide successfully uploaded",
          description: "We will notify you once it's processed",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.code !== "ERR_CANCELED")
        toast({
          title: "Slide Uploading Failed",
          description: "Something went wrong, try uploading again!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
    }
    setOpenModel(true);
    setIsUploading(false);
    setProgress(0);
    setSelectedFile(null);
    setAbortController(null);
    // setSlideName("");
    setGrossId("");
    setBlockId("");
    // setClose(false);
    setLoading(false);
  };

  // useEffect(() => {
  //   setSlideName(
  //     `${accessionId}_${moment(new Date()).format("DD/MM/YYYY")}_${
  //       caseInfo.caseId
  //     }_${caseInfo?.organs[0].organName
  //       .replace(/[aeiou]/gi, "")
  //       .toLowerCase()}_${slideType}`
  //   );
  // }, [accessionId, slideType]);

  return (
    <Flex
      direction="column"
      w="100%"
      // h={isUploading ? "18vh" : "62vh"}
      h={isUploading ? (openModel ? "62vh" : "18vh") : "62vh"}
      fontSize={ifWidthLessthan1920 ? "13px" : "0.729vw"}
      fontFamily="Inter"
      minH="250px"
    >
      <Text fontWeight="600" fontSize="16px" mb="2vh">
        {isUploading ? "Uploading" : "Upload a new Slide"}
      </Text>
      {!isUploading ? (
        <>
          <Flex w="100%" justifyContent="space-between" mb="1vh">
            <Flex direction="column" w="60%">
              <Text mb="0.5vh" fontWeight={600}>
                Case Details
              </Text>
              <VStack p={0} w="100%" alignItems="flex-start" spacing={1}>
                <Flex alignItems="flex-start" w="80%">
                  <Text w="35%">Case ID:</Text>
                  <Text w="65%">
                    {caseInfo?.caseId ? caseInfo?.caseId : caseInfo?._id}
                  </Text>
                </Flex>
                <Flex alignItems="flex-start" w="80%">
                  <Text w="35%">Department:</Text>
                  <Text w="65%">{caseInfo?.departmentFrom.toLowerCase()}</Text>
                </Flex>
                <Flex alignItems="flex-start" w="80%">
                  <Text w="35%">Organ:</Text>
                  <Text w="65%">{caseInfo?.organs[0].organName}</Text>
                </Flex>
              </VStack>
            </Flex>
            <Flex direction="column" w="40%">
              <Text mb="0.5vh" fontWeight={600}>
                Patient Details
              </Text>
              <VStack p={0} w="100%" alignItems="flex-start" spacing={1}>
                <Flex alignItems="flex-start" w="100%">
                  <Text w="20%">UHID:</Text>
                  <Text w="80%">{caseInfo?.patient.uhid}</Text>
                </Flex>
                <Flex alignItems="flex-start" w="100%">
                  <Text w="20%">Name:</Text>
                  <Text w="80%">{`${caseInfo?.patient?.patientName?.firstName}${caseInfo?.patient?.patientName?.lastName}`}</Text>
                </Flex>
              </VStack>
            </Flex>
          </Flex>
          <VStack alignItems="flex-start" mb="2vh">
            <Flex alignItems="center">
              <Text mr="5px">Slide Name*</Text>
              <Tooltip
                placement="bottom"
                openDelay={0}
                bg="#E4E5E8"
                color="rgba(89, 89, 89, 1)"
                fontSize="14px"
                fontFamily="inter"
                hasArrow
                borderRadius="0px"
                size="20px"
                label="This is an editable name which you can give to your slide.
                By default slidename is a combination of accession ID, current date, slide id, organ name, and stain type selected."
              >
                <InfoIcon color="#1B75BC6E" />
              </Tooltip>
            </Flex>
            <Input
              w="100%"
              fontSize="14px"
              h={ifWidthLessthan1920 ? "30px" : "4vh"}
              borderRadius={0}
              _focus={{ outline: "none" }}
              placeholder="Slide Title*"
              // defaultValue={slideName}
              onChange={(e) => setSlideName(e.target.value)}
              value={slideName}
              required
            />
            <Text>Accession ID*</Text>
            <Input
              w="100%"
              fontSize="14px"
              h={ifWidthLessthan1920 ? "30px" : "4vh"}
              borderRadius={0}
              _focus={{ outline: "none" }}
              placeholder="Accession ID*"
              onChange={(e) => setAccessionId(e.target.value)}
              value={accessionId}
            />
            <Flex>
              <Box mr="60px">
                {" "}
                <Text>Gross ID</Text>
                <Input
                  w="250px"
                  h={ifWidthLessthan1920 ? "30px" : "4vh"}
                  borderRadius={0}
                  _focus={{ outline: "none" }}
                  placeholder="Gross ID"
                  onChange={(e) => setGrossId(e.target.value)}
                  value={grossId}
                />
              </Box>
              <Box>
                {" "}
                <Text>Block ID</Text>
                <Input
                  w="250px"
                  h={ifWidthLessthan1920 ? "30px" : "4vh"}
                  borderRadius={0}
                  _focus={{ outline: "none" }}
                  placeholder="Block ID"
                  onChange={(e) => setBlockId(e.target.value)}
                  value={blockId}
                />
              </Box>
            </Flex>
          </VStack>
          <Flex
            w="100%"
            justifyContent="space-between"
            h="10%"
            alignItems="center"
          >
            <RadioGroup
              onChange={(e) => setSlideType(e)}
              defaultValue={slideType === "IHC" ? "IHC" : "H&E"}
            >
              <HStack spacing="75px" h="50%">
                <Radio
                  value="IHC"
                  _focus={{ outline: "none" }}
                  borderRadius={0}
                >
                  IHC
                </Radio>
                {/* <Radio
                  value="specialStain"
                  _focus={{ outline: "none" }}
                  borderRadius={0}
                >
                  Special stain
                </Radio> */}
                <Radio
                  value="H&E"
                  _focus={{ outline: "none" }}
                  borderRadius={0}
                >
                  H&E
                </Radio>
              </HStack>
            </RadioGroup>
            {slideType === "IHC" ? (
              <Menu>
                <MenuButton
                  as={Button}
                  bgColor="#F6F6F6"
                  border="1px solid #DEDEDE"
                  w="250px"
                  fontWeight="500"
                  borderRadius={0}
                  ml="20px"
                  h="30px"
                  mb="10px"
                >
                  <Flex
                    alignItems="center"
                    w="100%"
                    justifyContent="space-between"
                    opacity={0.4}
                    mr="30px"
                  >
                    <Text>
                      {strainType !== null ? strainType : "Select marker type*"}
                    </Text>
                    <ChevronDownIcon />
                  </Flex>
                </MenuButton>
                <MenuList minWidth="250px" borderRadius={0} p={0}>
                  <MenuOptionGroup
                    defaultValue="asc"
                    type="radio"
                    onChange={(e) => setStrainType(e)}
                  >
                    <MenuItemOption
                      value="kI67"
                      _hover={{ bgColor: "#1B75BC6E" }}
                    >
                      KI-67
                    </MenuItemOption>
                    <MenuItemOption
                      value="HER-2"
                      _hover={{ bgColor: "#1B75BC6E" }}
                    >
                      HER-2
                    </MenuItemOption>
                    <MenuItemOption
                      value="CD20"
                      _hover={{ bgColor: "#1B75BC6E" }}
                    >
                      CD20
                    </MenuItemOption>
                    <MenuItemOption
                      value="EGFR"
                      _hover={{ bgColor: "#1B75BC6E" }}
                    >
                      EGFR
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            ) : null}
          </Flex>

          <Flex
            w="100%"
            h="150px"
            gap="10px"
            alignSelf="center"
            border="1px dashed #1B75BC6E"
            borderRadius="10px"
            onClick={handleFileInputClick}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDragFile(e)}
          >
            <Input
              type="file"
              ref={uploadInputRef}
              style={{ display: "none" }}
              onChange={handleInputChange}
              accept=".tif,.tiff,.svs,.dcm,.dicom"
            />
            <Flex
              w="30vw"
              h="100px"
              margin="auto"
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <MdFileUpload
                style={{
                  width: "100%",
                  height: "20%",
                  color: "#1B75BC6E",
                }}
              />
              <Flex>
                {selectedFile ? (
                  <Text fontSize="1vw" opacity="0.7" textAlign="center">{`${
                    selectedFile.name
                  } - ${selectedFile.size / 1000000} MB`}</Text>
                ) : (
                  <Flex>
                    <Text fontSize="1vw" opacity="0.7" textAlign="center">
                      Drop your slide here or
                    </Text>
                    <Text
                      fontSize="1vw"
                      opacity="0.7"
                      ml="5px"
                      color="light.1005"
                      fontWeight="600"
                    >
                      Browse
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </>
      ) : (
        <Progress value={progress} min={0} max={100} minW="700px" />
      )}

      <HStack mt="10px" justifyContent="flex-end" mb="5px">
        <Button
          alignItems="center"
          bgColor="#fff"
          color="light.700"
          border="1px solid #1B75BC"
          size="md"
          width="80px"
          height="40px"
          fontSize="14px"
          _focus={{ outline: "none" }}
          borderRadius="5px"
          fontWeight="400"
          onClick={cancelFileSelection}
          display={!isUploading ? "none" : ""}
        >
          Cancel
        </Button>
        <Button
          alignItems="center"
          bgColor="#1B75BC"
          color="light.100"
          _hover={{ bgColor: "#1B75BC" }}
          size="md"
          _focus={{ outline: "none" }}
          borderRadius="5px"
          fontWeight="400"
          width="80px"
          height="40px"
          fontSize="14px"
          disabled={
            selectedFile === null ||
            isUploading ||
            (slideType === "IHC" && strainType === null) ||
            accessionId.length === 0 ||
            slideName.length === 0
          }
          display={isUploading ? "none" : ""}
          onClick={uploadFiles}
        >
          Upload
        </Button>
      </HStack>
    </Flex>
  );
};

export default UploadSlides;
